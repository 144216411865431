import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { MappedImage } from '../Image'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhoneVolume,
  faEnvelope,
  faBars,
  faCheck,
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

let locationPathname =
  typeof window !== 'undefined' ? window.location.pathname : ''

export const Header = ({
  orgId,
  phoneNumber,
  phoneNumberExtras,
  hoursAndInfo,
  emailAddress,
  primaryLogo,
  secondaryLogo,
  headerPrimaryLogo,
  headerSecondaryLogo,
  languageOptions,
  languageSelectorText,
  slug,
}) => {
  const isScanProvider = orgId === 'SCAN'
  const isNotAvailablePage = slug === 'not-available'
  const [dropdownIcon, setDropdownIcon] = useState(false)
  const [checkmark, setCheckmark] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions ? languageOptions[0] : null
  )
  const navTitle = (
    <span
      className="nav-dropdown-title"
      onClick={() => setDropdownIcon(prev => !prev)}
    >
      {languageSelectorText}
      <FontAwesomeIcon
        className="nav-dropdown-title-fa-icon"
        icon={dropdownIcon ? faChevronUp : faChevronDown}
      />
    </span>
  )
  const handleOnClick = () => {
    setIsClicked(prev => !prev)
    setExpanded(!expanded)
  }
  const handleLanguageSelect = eventKey => {
    setSelectedLanguage(eventKey)
    setCheckmark(prev => !prev)
  }

  const getLanguageOption = (urlLanguage, languageOptions) => {
    const options = {
      en: ['english', 'inglés'],
      es: ['español'],
    }

    const availableOptions = options[urlLanguage]
    if (availableOptions) {
      return languageOptions.find(option =>
        availableOptions.includes(option.toLowerCase())
      )
    }

    return null
  }

  useEffect(() => {
    // Check if the URL language matches a language option
    const urlLanguage = locationPathname.split('/')[1]
    const languageOption = getLanguageOption(urlLanguage, languageOptions)

    if (languageOption) {
      setSelectedLanguage(languageOption)
      setCheckmark(true)
    }
  }, [languageOptions])
  // let url = useSearchParams(slug).split('?')
  return (
    <Container as="header" fluid className="header-outer p-sm-1 p-md-3">
      <Row className="header-inner mx-auto px-sm-1 px-md-4">
        <Col xs={12} sm={4} className="d-flex header-logo-outer">
          <div className="header-logo-inner">
            {(headerPrimaryLogo || primaryLogo) && (
              <MappedImage
                image={headerPrimaryLogo || primaryLogo}
                style={{ paddingRight: '2rem' }}
                alt={`${headerPrimaryLogo?.title || primaryLogo?.title}`}
              />
            )}
            {(headerSecondaryLogo || secondaryLogo) && (
              <MappedImage
                image={headerSecondaryLogo || secondaryLogo}
                alt={`${headerSecondaryLogo?.title || secondaryLogo?.title}`}
              />
            )}
          </div>
        </Col>
        <Col
          xs={12}
          sm={8}
          className="d-flex justify-content-end pr-4 xs-justify-content-center"
        >
          <div className="sm-text-center d-inline-flex text-end my-2 header-contact">
            <div className="contact-wrapper mx-3">
              {phoneNumber && (
                <div className="p-0 m-0">
                  <FontAwesomeIcon
                    icon={faPhoneVolume}
                    style={{
                      color: '#71bf44',
                      fontSize: '24px',
                      marginRight: '10px',
                      fontFamily: 'Montserrat',
                    }}
                  />
                  <a href={'tel:' + phoneNumber}>{phoneNumber}</a>
                  {phoneNumberExtras && ' ' + phoneNumberExtras}
                </div>
              )}
              {hoursAndInfo && (
                <div className="hours-and-info-block">
                  <p>{parse(hoursAndInfo)}</p>
                </div>
              )}
              {emailAddress && (
                <div className="p-0 m-0">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{
                      color: '#71bf44',
                      fontSize: '24px',
                      marginRight: '10px',
                    }}
                  />
                  <a href={emailAddress}>{emailAddress}</a>
                </div>
              )}
            </div>
            {(isScanProvider || isNotAvailablePage) && (
              <Col className="header-navbar mx-3 my-2">
                <Navbar
                  className="d-flex justify-content-center"
                  collapseOnSelect
                  expand="lg"
                  bg="transparent"
                  variant="light"
                  expanded={expanded}
                >
                  <Container fluid>
                    <Navbar.Toggle
                      className=""
                      aria-controls="responsive-navbar-nav"
                      onClick={handleOnClick}
                    >
                      <FontAwesomeIcon
                        className="navbar-burger-menu"
                        icon={!isClicked ? faBars : faTimes}
                      />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav" expanded="true">
                      <Nav className="nav ms-auto">
                        {languageOptions && (
                          <NavDropdown
                            title={navTitle}
                            id="collasible-nav-dropdown"
                            className="nav-dropdown-wrapper"
                          >
                            {languageOptions.map(language => (
                              <NavDropdown.Item
                                key={`${language}`}
                                href={`/${
                                  language === languageOptions[0] ? 'en' : 'es'
                                }/${slug}/?orgId=${orgId}`}
                                className={language}
                                eventKey={language}
                                onSelect={handleLanguageSelect}
                              >
                                {language}{' '}
                                {selectedLanguage === language && checkmark && (
                                  <FontAwesomeIcon
                                    className="selected-lang-fa-icon"
                                    icon={faCheck}
                                  />
                                )}
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        )}
                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </Col>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export const mapHeaderProps = props => {
  const {
    orgId,
    phoneNumber,
    phoneNumberExtras,
    hoursAndInfo,
    emailAddress,
    contactWrapperOne,
    contactWrapperTwo,
    languageOptions,
    languageSelectorText,
    primaryLogo,
    secondaryLogo,
    headerPrimaryLogo,
    headerSecondaryLogo,
    slug,
  } = props
  return {
    orgId,
    phoneNumber,
    phoneNumberExtras,
    hoursAndInfo,
    emailAddress,
    contactWrapperOne,
    contactWrapperTwo,
    languageOptions,
    languageSelectorText,
    primaryLogo,
    secondaryLogo,
    headerPrimaryLogo,
    headerSecondaryLogo,
    slug,
  }
}
